.regex-tester-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.regex-textarea {
  width: 100%;
  height: 300px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

.regex-textarea:focus {
  border-color: #007bff;
  outline: none;
}

.button-container {
  text-align: right;
  margin-bottom: 10px;
}

.clear-button {
  background-color: #2c5872;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.clear-button:hover {
  background-color: #5f8ebd;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.results-container {
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.results-container h3{
  margin: 0px;
}
.match-success {
  color: #28a745;
  font-size: 18px;
  font-weight: bold;
}

.match-failure {
  color: #dc3545;
  font-size: 18px;
  font-weight: bold;
}

@media(min-width: 768px) {
  .input-container {
    flex-direction: row;
  }

  .regex-textarea {
    flex: 1;
  }
}
