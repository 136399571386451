.course-card h1{
    font-size: 2.5rem;
    font-weight: 900;
    color: #18435c;
    margin: 10px 0px;
}

.course-card p{
    margin-bottom: 20px;
}

.courses-section{
    margin-bottom: 20px;
}