.recommended-color-header{
    background-color: #1e2359;
    color: white;
    padding: 20px 20px;
    border-radius: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin: 4% 0px;
}

.color-picker-header{
    background-color: #0e4f47;
    color: white;
    padding: 20px 20px;
    border-radius: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin: 4% 0px;
}