.title{
    font-size: 2.5rem;
    font-weight: 700;
}

.subtitle{
    font-size: 1rem;
    font-weight: 200;
    margin-top: 10px;
    margin-bottom: 1rem;
}

.back-button{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    background-color: rgb(29, 57, 114);
    border-radius: 10px;
    color: white;
    padding:10px;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.2s;
}

.back-button:hover{
    background-color: rgb(79, 106, 161);
}

.post-title-section{
    margin-bottom: 2rem;
    background-color: #f1f1f1;
    padding: 40px;
    border-radius: 20px;
}

.post-content-section h1, h2, h3, h4, h5, h6{
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.post-content-section a{
    color: #188c9b;
    text-decoration: underline;
}

.post-content-section a:hover{
    color: #0a5660;
    text-decoration: none;
}

.post-content-section h1{
    font-size: 1.5rem;
    font-weight: 700;
}

.post-content-section h2{
    font-size: 1.3rem;
}

.post-content-section h3{
    font-size: 1.1rem;
}

.post-content-section table{
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border-radius: 100px;
}
.post-content-section th{
    background-color: rgb(1, 22, 39);;
    font-weight: bold;
    color: #f1f1f1;
    padding: 10px;
    margin: 10px;
}
.post-content-section td{
    background-color: #f1f1f1;
    padding: 10px;
    margin: 10px;
}

.post-content-section ol{
    list-style-type: decimal;
    margin-left: 2rem;
    margin-top: 1rem;
}

.post-content-section p{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.1rem;
}

.category{
    background-color: #741678;
    color: white;
    padding: 2px 10px;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: bold;
}

.code-block{
    position: relative;
    border-radius: 50px !important;
}

.copy-button{
    position: absolute;
    background-color: #0a5660;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.2s;
    top: 4px;
    right: 10px;
}