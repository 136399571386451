.query-content{
  padding:20px; 
  background:#4b7796; 
  color: antiquewhite;
  margin-bottom: 20px;
  border-radius: 5px;
}

.full-height{
  min-height: 70vh;
}