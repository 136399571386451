.small-light-header-section{
    background-color: #112836;
    padding: 10px 25px;
}
.small-light-header-section h1{
    margin: 0px;
    font-weight: 700;
    font-size: 1.6rem;
    color: #c7d9e4;
}
.small-light-header-section p{
    color: #18435c;
}
