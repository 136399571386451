.detatch-light-header-section{
    background-color: #def4f7;
    padding: 50px 50px;
    /* margin: 20px;
    border-radius: 20px; */
}
.detatch-light-header-section h1{
    margin: 0px;
    font-weight: 900;
    color: #18435c;
}
.detatch-light-header-section p{
    color: #18435c;
}

@media (min-width: 768px) {
    .detatch-light-header-section h1 {
        font-size: 4rem;
    }
}

@media (max-width: 767px) {
    .detatch-light-header-section h1 {
        font-size: 2rem;
    }
}